import MicroModal from './vendor/micromodal'

MicroModal.init({
  onShow: (modal) => console.info(`${modal.id} is shown`),
  onClose: (modal) => console.info(`${modal.id} is hidden`),
  openTrigger: 'data-custom-open',
  closeTrigger: 'data-custom-close',
  openClass: 'is-open',
  disableScroll: true,
  disableFocus: false,
  awaitOpenAnimation: false,
  awaitCloseAnimation: false,
  debugMode: true,
})

// scroll up
let scrollPosition = window.scrollY
const scrollup = document.getElementById('scroll-up')

window.addEventListener('scroll', function () {
  scrollPosition = window.scrollY

  if (scrollPosition >= 500) {
    scrollup.classList.add('show')
  } else {
    scrollup.classList.remove('show')
  }
})

scrollup.addEventListener('click', function (e) {
  e.preventDefault()
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  })
})

// hide alert
setTimeout(() => {
  const alert = document.getElementById('popup')
  if (alert) {
    alert.style.display = 'none'
  }
}, 5000)

// toggle navigation
document.getElementById('modal_open').addEventListener('click', function (e) {
  document.getElementById('menu-btn').checked = false
})

// Slider
let slideIndex = 1
showSlides(slideIndex)

function nextSlide() {
  showSlides((slideIndex += 1))
  console.info('clic')
}

function prevSlide() {
  console.info('clic')
  showSlides((slideIndex -= 1))
}

function currentSlide(n) {
  showSlides((slideIndex = n))
}

document.getElementById('prev').addEventListener('click', function () {
  prevSlide()
})
document.getElementById('next').addEventListener('click', function () {
  nextSlide()
})

function showSlides(n) {
  let i
  let slides = document.getElementsByClassName('carousel-item')

  if (n > slides.length) {
    slideIndex = 1
  }
  if (n < 1) {
    slideIndex = slides.length
  }

  for (let slide of slides) {
    slide.style.display = 'none'
  }

  if (slides) {
    slides[slideIndex - 1].style.display = 'block'
  }
}
